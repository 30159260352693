import { Link } from 'gatsby'
import Noimage from './noimage'
import Eyecatch from './eyecatch'
import PropTypes from 'prop-types'
import React from 'react'

const Card = ({ post }) => (
  <section key={post.slug} className={'post-item'}>
    <Link to={'/post/' + post.slug + '/'}>
      <figure className="post-thumbnail">
        {post.featured_media ? (
          <Eyecatch slug={post.slug} alt={post.title} />
        ) : (
          <Noimage alt="noimage" />
        )}
      </figure>
    </Link>
    <header className="entry-header">
      <h2 className="entry-title">
        <Link to={'/post/' + post.slug + '/'}>{post.title}</Link>
      </h2>
    </header>
    <time className="entry-date">{post.date}</time>
    <div className="cat-links">
      {post.categories.map(category => (
        <Link to={'/category/' + category.slug + '/'} key={category.slug}>
          <span>{category.name}</span>
        </Link>
      ))}
    </div>
    <div className="tag-links">
      {post.tags
        ? post.tags.map(tag => (
            <Link to={'/tag/' + tag.slug + '/'} key={tag.slug}>
              <span>{tag.name}</span>
            </Link>
          ))
        : ''}
    </div>
  </section>
)

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Card
